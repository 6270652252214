import axios from "../../axios"


export const coverageCreate = (data) => (dispatch) => {
	return axios.post('coverage/check', data).then(resp => {
		return resp.data;
	})
}

// export const coverageUpdate = (data) => (dispatch) => {

// 	return axios.put('coverage/update', data).then(resp => {
// 		return resp.data;
// 	})
// }

export const coverageSignUp = (data) => (dispatch) => {

	return axios.post('coverage/signup', data).then(resp => {
		return resp.data;
	})
}

