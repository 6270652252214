import React from 'react';
import styled from 'styled-components';

/**
 * @typedef {Object} RadioOption
 * @property {string} value - The value of the radio button. Used to identify the selected option.
 * @property {string} label - The display label for the radio button.
 * @property {boolean} disabled - Whether the radio button is disabled.
 */

/**
 * Radio component renders a group of radio buttons.
 *
 * @param {Object} props - The properties object.
 * @param {RadioOption[]} props.options - The array of options for the radio buttons. Each option should be an object with `value` and `label` properties.
 * @param {string} props.name - The name attribute for the radio buttons.
 * @param {string} props.selectedValue - The currently selected value.
 * @param {function} props.onChange - The callback function to handle the change event when a radio button is selected.
 *
 * @returns {JSX.Element} The rendered Radio component.
 */

const Radio = ({ options = [], name, selectedValue, onChange }) => {
  return (
    <StyledWrapper>
      <div className="radio-inputs w-100">
        {options.map((option) => (
          <label className={option?.disabled ? `radio disabled` : `radio`} key={option.value}>
            <input
              type="radio"
              name={name}
              value={option.value}
              disabled={option?.disabled}
              checked={selectedValue === option.value}
              onChange={() => onChange(option.value)}
            />
            <span className="name">{option.label}</span>
          </label>
        ))}
      </div>
    </StyledWrapper>
  );
};


const StyledWrapper = styled.div`
  .radio-inputs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: #cbe1fb;
    box-sizing: border-box;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    padding: 0.25rem;
    width: 300px;
    font-size: 14px;
  }

  .radio-inputs .radio {
    flex: 1 1 auto;
    text-align: center;
  }

  .radio-inputs .radio input {
    display: none;
  }

  .radio-inputs .radio .name {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: none;
    padding: .5rem 0;
    color: rgba(51, 65, 85, 1);
    transition: all .15s ease-in-out;
  }

  .radio-inputs .radio input:checked + .name {
    background-color: #fff;
    font-weight: 600;
  }

  .radio-inputs .radio.disabled .name {
    cursor: not-allowed;
    color: rgba(82, 82, 82, 0.5);
  }

  .radio-inputs .radio.disabled input:disabled + .name {
    pointer-events: none;
  }
  `;

export default Radio;
