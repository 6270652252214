import React, { useEffect, useState, useContext } from "react";
import { CoverageContext } from "contexts/FormsContext";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumber } from "libphonenumber-js";
import RoundedButton from "components/Buttons/RoundedButton";
import { useForm } from "react-hook-form";
import Radio from "components/Radio/Radio";
import { useDispatch } from "react-redux";
import { coverageCreate, coverageUpdate } from "redux/actions/CoverageActions";
import { unformatedPhone } from "general/FormatNumber";
import { toast } from "react-toastify";

const AddingNumbers = ({ setStep, setProcessing, activeStep, heading, placeholder, prevStep }) => {

	const dispatch = useDispatch();
	const { coverage, setCoverage } = useContext(CoverageContext)
	const { formState: { errors }, setError, clearErrors, handleSubmit, setValue, watch } = useForm({
		defaultValues: {
			checkerType: "customer",
			checkedBy: coverage?.checkedBy
		}
	})

	const [dynamicFormNumbers, setDynamicFormNumbers] = useState([{
		'number': "",
		"country": "",
		"phone_type": "",
		"current_phone_type": ""
	}])

	const [options, setOptions] = useState([]);

	const [toNumberOptions, setToNumberOptions] = useState([]);
	const [loading, setLoading] = useState(false);

	const handlePhoneNumberChange = (phone, details, index) => {
		clearErrors(`numbers.${index}.phoneNumber`);

		let phoneNumber = null;
		if (phone.length >= 11) {
			phoneNumber = parsePhoneNumber(phone, details?.countryCode?.toUpperCase());
			if (!phoneNumber.isValid() || phoneNumber.getType() === undefined) {
				setError(`numbers.${index}.phoneNumber`, { message: "Number is invalid" });
			}

			if (["FIXED_LINE", "FIXED_LINE_OR_MOBILE", "MOBILE"].includes(phoneNumber.getType())) {

				setOptions(prevVal => {
					return {
						...prevVal, [index]: [
							{
								label: "Landline",
								value: "landline",
								disabled: false,
							},
							{
								label: "Fax",
								value: "fax",
								disabled: false,
							},
							{
								label: "Toll-Free",
								value: "toll_free",
								disabled: true
							}
						]
					}
				})
			} else if (["TOLL_FREE"].includes(phoneNumber.getType())) {
				setOptions(prevVal => {
					return {
						...prevVal, [index]: [
							{
								label: "Landline",
								value: "landline",
								disabled: true,
							},
							{
								label: "Fax",
								value: "fax",
								disabled: true,
							},
							{
								label: "Toll-Free",
								value: "toll_free",
								disabled: false
							}
						]
					}
				})
			}
		}

		setDynamicFormNumbers((prevNumbers) =>
			prevNumbers.map((num, i) =>
				i === index
					? { ...num, number: phone, country: details.countryCode.toUpperCase(), current_phone_type: phoneNumber?.getType()?.toLowerCase(), phone_type: phoneNumber?.getType()?.toLowerCase() }
					: num
			)
		);
		populateSelectToOption(phoneNumber?.getType()?.toLowerCase(), index)

	};

	const addDynamicRow = () => {
		if (dynamicFormNumbers.length < 5) {
			setDynamicFormNumbers([...dynamicFormNumbers, {
				'number': "",
				"country": "",
				"phone_type": "",
				"current_phone_type": ""
			}])
		}
	}

	const removeDynamicRow = (index) => {
		setDynamicFormNumbers(dynamicFormNumbers.filter((_, i) => i !== index));
	};

	const populateSelectToOption = (value, index) => {
		clearErrors();
		const mapping = {
			landline: [
				{ label: "Landline", value: "landline" },
				{ label: "Fax", value: "fax" }
			],
			fixed_line_or_mobile: [
				{ label: "Landline", value: "landline" },
				{ label: "Fax", value: "fax" }
			],
			fax: [
				{ label: "Landline", value: "landline" },
				{ label: "Fax", value: "fax" }
			],
			toll_free: [
				{ label: "Toll-Free", value: "toll_free" },
				{ label: "Fax", value: "fax" }
			]
		};

		setToNumberOptions(prevOptions => {
			const newOptions = { ...prevOptions, [index]: mapping[value] };
			return newOptions;
		});

		setDynamicFormNumbers(prevNumbers =>
			prevNumbers.map((num, i) =>
				i === index
					? { ...num, current_phone_type: value, phone_type: value || "" }
					: num
			)
		);
	};

	const onSubmit = async (data) => {

		try {
			// Clear previous errors
			clearErrors();
			setLoading(true)
			let isValid = true;
			const typeMapping = {
				landline: ["FIXED_LINE", "FIXED_LINE_OR_MOBILE"],
				// mobile: ["MOBILE", "FIXED_LINE_OR_MOBILE"],
				fax: ["FIXED_LINE", "FIXED_LINE_OR_MOBILE"],
				toll_free: ["TOLL_FREE"]
			};

			const validatedNumbers = dynamicFormNumbers.map((num, index) => {
				try {
					if (!num.number || num.number.length < 11) {
						setError(`numbers.${index}.phoneNumber`, { message: "Invalid phone number format" });
						isValid = false;
						return null;
					}

					const phoneNumber = parsePhoneNumber(num.number, num.country?.toUpperCase());
					if (!phoneNumber.isValid()) {
						setError(`numbers.${index}.phoneNumber`, { message: "Invalid phone number" });
						isValid = false;
						return null;
					}

					const detectedType = phoneNumber.getType();

					if (!typeMapping[num.phone_type]?.includes(detectedType)) {
						setError(`numbers.${index}.phone_type`, {
							type: "manual",
							message: `Select valid current number type and port number as`
						});
						isValid = false;
						return null;
					}

					return {
						number: num.number,
						country: num.country,
						phone_type: num.phone_type
					};
				} catch (error) {
					setError(`numbers.${index}.phoneNumber`, { message: "Error parsing phone number" });
					isValid = false;
					return null;
				}
			});

			if (!isValid) {
				setLoading(false);
				return;
			}

			if (coverage.phone) {
				parsePhoneNumber(coverage.phone, dynamicFormNumbers[0].country);
			}

			const payload = {
				'fname': coverage.firstname,
				'lname': coverage.lastname,
				'email': coverage.email,
				'country': dynamicFormNumbers[0].country,
				"checkedBy": coverage.checkedBy || "",
				"checkerType": "customer",
				"check_portability": dynamicFormNumbers
			}

			if (coverage.phone) {
				payload['phone'] = unformatedPhone(coverage.phone)
			}

			if (coverage?.id) {
				payload['id'] = coverage.id
			}

			await dispatch(coverageCreate(payload)).then(response => {

				console.log("response", response)
				if (!response.bool) {
					setLoading(false)
					toast.error(response.message, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3500, theme: "light" });
					return false;
				}
				setCoverage({
					...coverage,
					'id': response?.result?.coverage?.id,
					'portabilityResponse': response?.result?.portability_result,
					'port': validatedNumbers.map((d, i) => {
						return {
							'number': d.number,
							'result': response?.result?.portability_result[i]
						}
					})
				})
				setStep(activeStep + 1)

			}).finally(f => setLoading(false));

		} catch (error) {
			setLoading(false)
			console.log(error)
			toast.error(error.message, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3500, theme: "light" });
		}
	}

	console.log(errors)

	return (
		<>
			<div className="step" style={{ minWidth: "1000px", padding: "50px" }}>
				<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
				<h2 className="lg-heading mb-5">Use the form below to see if your number is eligible for Park My Phone phone number storage.</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-4">
							<label className="form-label select-label mb-0">Enter Number:</label>

						</div>
						<div className="col-7">
							<label className="form-label select-label mb-0 col-6">Current Number Type</label>
							<label className="form-label select-label mb-0 col-6">Port Number As</label>

						</div>
						<div className="col-1">
							<label className="form-label select-label mb-0"></label>
						</div>
					</div>
					{
						dynamicFormNumbers.map((dynamicFormNumber, dynamicFormNumberKey) => {
							return (
								<div className="row" key={dynamicFormNumberKey}>
									<div className="col-4">
										<PhoneInput
											onlyCountries={['us', 'ca']}
											disableDropdown={true}
											countryCodeEditable={false}
											inputStyle={{ fontSize: 18, paddingLeft: "70px" }}
											inputClass="form-control-lg w-100"
											value={dynamicFormNumber.number}
											country={'us'}
											onChange={(phone, details) => {
												handlePhoneNumberChange(phone, details, dynamicFormNumberKey);
											}}
										/>
										{errors?.numbers?.[dynamicFormNumberKey]?.phoneNumber && (
											<span className="text-danger">{errors.numbers[dynamicFormNumberKey].phoneNumber.message}</span>
										)}
									</div>
									<div className="col-7">
										<div className="row">
											<div className="col-6">
												<div className="col d-flex flex-column mb-2">
													<Radio
														name={`current_number_state_${dynamicFormNumberKey}`}
														options={options[dynamicFormNumberKey]}
														selectedValue={dynamicFormNumber.current_phone_type}
														onChange={(value) => populateSelectToOption(value, dynamicFormNumberKey)}
													/>
													{errors?.numbers?.[dynamicFormNumberKey]?.current_phone_type && (
														<span className="text-danger">{errors.numbers[dynamicFormNumberKey].current_phone_type.message}</span>
													)}
												</div>
											</div>
											<div className="col-6">
												<div className="w-100">
													{
														dynamicFormNumber.phone_type && (
															<Radio
																name={`to_number_state_${dynamicFormNumberKey}`}
																options={toNumberOptions[dynamicFormNumberKey] || []}
																selectedValue={dynamicFormNumber.phone_type}
																onChange={(value) =>
																	setDynamicFormNumbers(prevNumbers =>
																		prevNumbers.map((num, i) =>
																			i === dynamicFormNumberKey ? { ...num, phone_type: value } : num
																		)
																	)
																}
															/>
														)
													}
													{errors?.numbers?.[dynamicFormNumberKey]?.phone_type && (
														<span className="text-danger">{errors.numbers[dynamicFormNumberKey].phone_type.message}</span>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="col-1">
										{
											dynamicFormNumberKey === 0 ? (
												<button type="button" disabled={dynamicFormNumbers.length >= 5} onClick={addDynamicRow} className="btn btn-success text-white m-0">Add</button>
											) : (
												<button type="button" onClick={() => removeDynamicRow(dynamicFormNumberKey)} className="btn btn-danger text-white m-0">Del</button>
											)
										}
									</div>
								</div>
							);
						})
					}
					<div className="row position-relative">

					</div>
					{/* From Footer */}
					<>
						<div className={"footer"} >
							<div className="d-flex flex-column align-items-center">
								<RoundedButton loading={loading} loadingText={'Loading'} showLoader={true}>
									Proceed
								</RoundedButton>
								{/* <div className="pe">
								Press Enter
								<img src="/images/forms/icon-enter.png" alt="" />
							</div> */}
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</form>
			</div>
		</>
	)
}

export default AddingNumbers
