import { CoverageContext } from "contexts/FormsContext";
import useAuth from "hooks/useAuth";

import React, { useState } from "react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const FormFooter = ({
	setStep,
	activeStep,
	setError = () => { },
	centerProceed,
	disabled = false,
	action,
	final,
	login,
	setProcessing = () => { }
}) => {

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false)
	const { coverage, setCoverage } = useContext(CoverageContext)
	const { isAuthenticated } = useAuth();

	const onClickHandler = async () => {

		if (action === 'create') {

		} else {
			setStep(activeStep + 1)
		}

	}

	return (
		<>
			<div className={centerProceed ? "footer justify-content-center align-items-baseline" : "footer"} >
				{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
				{login &&
					<div className="d-flex flex-column align-items-center me-lg-3">
						{isAuthenticated ?

							<Link className="nav-link" to={'/customer/dashboard'}>
								<button type="button" onClick={() => setStep(activeStep + 1)} className="proceed-btn mb-2">
									Dashboard&nbsp;<i className="fa fa-sign-in" aria-hidden="true"></i>
								</button>
							</Link>

							:
							<Link className="nav-link" to={'/login'}>
								<button disabled={disabled} type="button" onClick={() => setStep(activeStep + 1)} className="proceed-btn mb-2">
									Login&nbsp;<i className="fa fa-sign-in" aria-hidden="true"></i>
								</button>
							</Link>

						}
					</div>
				}
				{
					((isAuthenticated) && (activeStep === 2)) ? "" :
						<div className="d-flex flex-column align-items-center">
							{login ?
								<Link className="nav-link" to={'/signup'}>
									<button type="button" onClick={() => setStep(activeStep + 1)} className="proceed-btn mb-2">
										Proceed&nbsp;<i className="fa fa-sign-in" aria-hidden="true"></i>
									</button>
								</Link>
								:
								<>
									<button disabled={coverage.error || loading || disabled} type="button" onClick={onClickHandler} className="proceed-btn mb-2">
										{
											loading ?
												<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
												:
												final ? "Submit" : "Proceed"
										}
									</button>
									<div className="pe">
										Press Enter
										<img src="/images/forms/icon-enter.png" alt="" />
									</div>
								</>
							}
						</div>
				}

			</div>
			{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
			<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
				<img src="/images/forms/icon-prev.png" alt="" />
			</button>
			{/* <a className="next-step" onclick="form.steps('next')"> */}
			{/* <button type="button" data-title="Next" onClick={() => setStep(activeStep + 1)} className="next-step elaboration right"> */}
			<button type="button" data-title="Next" disabled={coverage.error || loading} onClick={onClickHandler} className="next-step elaboration right">
				<img src="/images/forms/icon-next.png" alt="" />
			</button>
		</>
	)
}

export default FormFooter
